import { ColorName } from '@/lib';
import { defineStore } from 'pinia';

let intervalId: number;

export interface State {
  progress: number;
  color: ColorName;
}

export const useProgressStore = defineStore('progress', {
  state: (): State => ({
    progress: 0,
    color: 'primary-500'
  }),
  actions: {
    startProgress(time = 5000) {
      window.clearInterval(intervalId);
      this.progress = 0;
      const cut = 10000 / Math.floor(time);
      intervalId = window.setInterval(() => {
        const increase = Math.floor(cut * Math.random());
        this.progress = Math.min(99, this.progress + increase);
        if (this.progress > 95) {
          this.progress = 0;
          window.clearInterval(intervalId);
        }
      }, 100);
    },
    finishProgress() {
      window.clearInterval(intervalId);
      this.progress = 100;
      setTimeout(() => {
        this.progress = 0;
      }, 350);
    },
    removeProgress() {
      window.clearInterval(intervalId);
      this.progress = 0;
    },
    setError() {
      this.color = 'danger-600';
      window.clearInterval(intervalId);
      this.progress = 100;
      setTimeout(() => {
        this.$reset();
      }, 350);
    }
  }
});
