
import { Vue, Options } from 'vue-class-component';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import ComingSoon from '@/lib/components/BaseComingSoon.vue';

@Options({
  components: {
    DefaultLayout,
    ComingSoon
  }
})
export default class DashboardPage extends Vue {}
